<template>
  <dialog-outline v-model="showDialog">
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>
    <template v-slot:default>
      {{ dialogTitle }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:context>
      <!-- display prompt -->
      <div v-show="visiblePrompt">
        <br />
        <base-prompt :prompt="createPrompt()"></base-prompt>
      </div>

      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>

      <!-- display Progress -->
      <progress-display v-if="visibleProgress">
        {{ progressText }}
      </progress-display>
    </template>

    <!-- Dialog Actions -->
    <template v-slot:actions>
      <base-button @click="onOk" :color="promptColorClass">{{
        dialogOkCommandLabel
      }}</base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// model
import { actionResultType } from "@/model/action/actionModel";

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";
import { promptAbleMixin } from "@/mixins/shared/promptAble/promptAbleMixin";
import { progressAbleMixin } from "@/mixins/shared/progressAble/progressAbleMixin";
import {
  agendaItemOperation,
  findAgendaItemOperation
} from "@/model/agenda/item/agendaItemModel";

export default {
  name: "SubmitRecallAgendaItemsDialog",
  mixins: [dialogOutlineMixin, promptAbleMixin, progressAbleMixin],
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, owner:string, stateOwnerId:number, pageCount:number, version:number, versionDate:string, versionOwner:string, fileSize:number, comments:string, flags:number, ancestor: {id: number, name: string, categoryId: number, code: number, flags: number, isComposite:boolean, isDeleted: boolean, isDraft:boolean, isHidden:boolean, isLocked:boolean, isOnHold: boolean, isReadOnly: boolean, isRetained: boolean, recordType: {id:number, name: string}, createdBy: {id:number, name: string}, updatedBy: {id:number, name: string}}, localFile: {hasFile:boolean, isModified:boolean, pageCount:Number, extension: {type:number, extensions:Array, description:string}}, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[], operations: {name: string, allowed: boolean, valid: boolean}[], agendaItemOperations: {name: string, allowed: boolean, valid: boolean}[], meetingOperations: {name: string, allowed: boolean, valid: boolean}[]}}
     */
    record: undefined,

    /**
     * Function to perform
     */
    performAction: {
      type: Function,
      required: true
    },

    isRecall: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    /**
     * submit/recall Operation
     * @return {{name: string, icon: string, label: string, description:string}} submit/recall Operation
     */
    operation() {
      return this.isRecall
        ? findAgendaItemOperation(agendaItemOperation.recall)
        : findAgendaItemOperation(agendaItemOperation.submit);
    },

    /**
     * submit/recall Operation
     * @return {string} submit/recall operation Label
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * submit/recall Dialog Title
     * @return {string} submit/recall Dialog Title
     */
    dialogTitle() {
      return this.operationLabel;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return `${this.operationLabel} All Agenda Items`;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * Prompt text
     * remarks: overwrite promptAbleMixin.promptText
     * @return {string}
     */
    promptText() {
      return `Do you want to ${this.action}?`;
    },

    /**
     * current Prompt Type
     * remarks:  overwrite promptAbleMixin.currentPromptType
     * @return {string} current Prompt Type
     */
    currentPromptType() {
      return this.promptTypeName.info;
    },

    /**
     * Prompt Hint
     * remarks:  overwrite promptAbleMixin.promptHint
     * @return {string} Prompt Hint
     */
    promptHint() {
      return `${this.operationLabel} All Agenda Items under ${this.record?.recordType}: ${this.record?.name} `;
    }
  },
  methods: {
    async onOk() {
      try {
        this.visiblePrompt = false;

        this.clearAlert();
        this.showProgress(this.formatProgressText(this.action));

        const result = await this.performAction(this.operation);

        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else {
          this.internalAlert = this.createAlert(
            this.alertTypeName.error,
            this.formatAlertError(this.action, result.message),
            false
          );
        }
      } catch (e) {
        this.closeProgress();

        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e),
          false
        );
      } finally {
        this.closeProgress();
      }
    },

    /**
     * Handle on Dialog Opened event in order to initialize data
     */
    onDialogOpened() {
      this.internalAlert = undefined;
      this.visiblePrompt = true;

      this.closeProgress();
    },

    /**
     * Handle on Dialog Closed event in order to clear data
     */

    async onDialogClosed() {
      this.internalAlert = undefined;
      this.visiblePrompt = true;

      this.closeProgress();
    }
  }
};
</script>
